<template>
  <div class="rating">
    <span
      v-for="star in stars"
      :key="star"
      @click="setRating(star)"
      @mouseover="hoverRating(star)"
      @mouseleave="resetHover"
      class="star"
      :class="{ 'filled': star <= (hoveredRating || rating) }"
    >
      <svg
        class="mr-2"
        width="42"
        height="40"
        viewBox="0 0 42 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.171 12.4719L19.3679 2.00789C19.5192 1.70505 19.7518 1.45035 20.0398 1.27232C20.3278 1.0943 20.6596 1 20.9982 1C21.3368 1 21.6686 1.0943 21.9566 1.27232C22.2445 1.45035 22.4772 1.70505 22.6285 2.00789L27.8254 12.4719L39.4435 14.1599C39.7786 14.2064 40.0939 14.3461 40.3534 14.5631C40.6129 14.7802 40.8061 15.0658 40.911 15.3874C41.016 15.7089 41.0283 16.0535 40.9468 16.3818C40.8652 16.7101 40.6929 17.0088 40.4497 17.2439L32.0442 25.3839L34.0285 36.8839C34.2826 38.3599 32.7223 39.4839 31.3881 38.7879L20.9982 33.3559L10.6063 38.7879C9.2741 39.4859 7.71382 38.3599 7.96786 36.8819L9.95222 25.3819L1.54671 17.2419C1.30464 17.0067 1.13344 16.7082 1.05255 16.3805C0.971672 16.0528 0.984358 15.709 1.08917 15.3882C1.19398 15.0674 1.38672 14.7823 1.64547 14.5656C1.90422 14.3488 2.21861 14.2089 2.55289 14.1619L14.171 12.4719Z"
          :fill="star <= (hoveredRating || rating) ? 'gold' : 'white'"
          stroke="#afbfc6"
          stroke-width="1.875"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
  },
  data() {
    return {
      stars: [1, 2, 3],
      rating: this.value,
      hoveredRating: 0,
    };
  },
  methods: {
    setRating(star) {
      this.rating = star;
      this.$emit('my-event',{name : this.name , star : star}); // Emit the new rating value
    },
    hoverRating(star) {
      this.hoveredRating = star;
    },
    resetHover() {
      this.hoveredRating = 0;
    },
  },
  watch: {
    value(newVal) {
      this.rating = newVal; // Update local rating if prop changes
    },
  },
};
</script>

<style scoped>
.rating {
  display: flex;
  align-items: center;
}

.star {
  cursor: pointer;
}

.star.filled {
  color: gold;
}
</style>
